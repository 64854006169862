import React, { useState, useEffect, createRef, RefObject } from "react";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import rgba from "polished/lib/color/rgba";
import { css } from "@emotion/react";
import { Button } from "@ryerson/frontend.button";
import { Icon } from "@ryerson/frontend.assets";
import { Link } from "@ryerson/frontend.navigation";
import { SelectedProductType } from "./SelectedProduct";
import { displayPrice, getLeadTimeRangeDisplay, navigateToProductDetails } from "@ryerson/frontend.common";
import { IndexedLayers } from "@ryerson/frontend.common";
import { DiscountPriceModel } from "@ryerson/frontend.common";
import { useWindowSize } from "@ryerson/frontend.common";
import { emDash } from "@components/Helper/common";
import { getDisplayUom } from "@ryerson/frontend.common";
import { useApplication } from "@ryerson/frontend.application";

const GenericSelectedProductDetail = styled(Flex)`
	margin-left: 5%;
`;

const ImageContainer = styled.img`
	height: 50px;
	width: 50px;
	object-fit: contain;
	${(props: any) => {
		const { bgColor } = props;
		return css`
			background: ${bgColor};
		`;
	}};
`;

const SelectedProductContainer = styled(Flex)`
	position: relative;
	margin-bottom: 20px;
	padding: 0px 20px;
	box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
		0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725);
	border-radius: 2px;
	${(props: any) => {
		const { bgColor } = props;
		return css`
			background: ${bgColor};
			border: 1px solid ${bgColor};
		`;
	}};
`;

const SelectedProductDetailContainer = styled(Flex)`
	position: relative;
	margin: 20px 0px;
`;

const Divider = styled.div`
	${(props: any) => {
		const { color, opacity } = props;
		return css`
			border-top: 1px solid ${color};
			opacity: ${opacity};
		`;
	}};
`;

const LinkButtonContainer = styled(Flex)`
	position: relative;
	width: 100%;
	margin: 15px 0px;
`;

const marginTop = css`
	margin-top: 15px;
`;

const Label = styled(Typography)`
	margin-right: 10px;
`;

const FlexItemContent = styled(FlexItem)`
	margin-bottom: 3px;
`;

const addDefaultSrc = (event: any) => {
	event.target.src = "/NoImageImage.svg";
};

const fadeIn = css`
	animation: fadeIn linear 1s;
	-webkit-animation: fadeIn linear 1s;
	-moz-animation: fadeIn linear 1s;
	-o-animation: fadeIn linear 1s;
	-ms-animation: fadeIn linear 1s;
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;

export const SliderMenu = styled.div`
	transition: left 0.3s linear;
	position: fixed;
	width: 100%;
	top: 50px;
	height: 100vh;
	max-height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: ${IndexedLayers.header};

	&.slide-open {
		left: 0%;
	}

	&.slide-close {
		left: -100%;
	}

	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.white};
		`;
	}}
`;

const MoreYouBuyHeader = styled.div`
	height: 50px;
	width: 100%;
	top: 0;
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.lightGray};
			background-color: ${theme.colors.primary.darkGray};
		`;
	}}
`;

const MoreYouBuyRowContent = styled(Flex)`
	margin: 10px 10px;
	padding: 0px 10px;
	width: 100%;
`;

const SelectedProductMobile: React.FC<SelectedProductType> = ({
	isRetrieving,
	priceUom,
	qtyUom,
	convertedQuantity,
	convertedPrice,
	content,
	selectedProduct,
	getQueryString,
	currencyType,
	leadTimeAndDeliveryDate,
	canPriceView,
	filteredDiscountedPrice,
	hasDiscountPrices,
	onProductDetailsClick,
}) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	
	const { lightGray, lighterGray, white, primaryBrand, secondaryBrand, gray } =
		theme.colors.primary;

	const [hasMounted, setHasMounted] = useState<boolean>(false);
	const [moreYouBuyExpanded, setMoreYouBuyExpanded] = useState<boolean>(false);
	const [domNodeRefs] = useState<any>({});
	const windowSize = useWindowSize();
	const [bodyStyles, setBodyStyles] = useState<string>("");

	const getCorrectCase = (word: string) => {
		return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : "";
	};

	const creatDomNodeRef = (fieldName: string): RefObject<any> => {
		domNodeRefs[fieldName] = createRef();
		return domNodeRefs[fieldName];
	};

	useEffect(() => {
		if (hasMounted) {
			const sliderEl = domNodeRefs["more-you-buy-menu"].current;

			sliderEl?.classList?.toggle("slide-open");
			sliderEl?.classList?.toggle("slide-close");

			const body = document?.querySelector("body");

			if (moreYouBuyExpanded) {
				(window as any).scrollTo(0, 0);
				setBodyStyles(body?.getAttribute("style") || "");
				setTimeout(() => {
					body?.setAttribute("style", "overflow: hidden;");
				}, 100);
			} else {
				body && body.setAttribute("style", bodyStyles);
			}
		}
	}, [moreYouBuyExpanded]);

	useEffect(() => {
		setHasMounted(true);
		setMoreYouBuyExpanded(false);

		const body = document?.querySelector("body");

		if (moreYouBuyExpanded) {
			(window as any).scrollTo(0, 0);
			setBodyStyles(body?.getAttribute("style") || "");
			setTimeout(() => {
				body?.setAttribute("style", "overflow: hidden;");
			}, 100);
		} else {
			body && body.setAttribute("style", bodyStyles);
		}
	}, []);

	const isZero = convertedPrice === 0;
	const validPriceInfo = convertedPrice.toString() !== "NaN";

	return (
		<>
			<SelectedProductContainer {...{ bgColor: secondaryBrand }} direction="column">
				{canPriceView && hasDiscountPrices && (
					<LinkButtonContainer
						justifyContent="space-between"
						alignItems="center"
						onClick={() => {
							setMoreYouBuyExpanded(true);
						}}
					>
						<Typography color={lightGray} size="md">
							{content.theMoreYouBuy}
						</Typography>
						<Icon icon="chevron-right" color={lightGray} size="xs" />
					</LinkButtonContainer>
				)}

				<Divider color={white} {...{ opacity: "0.1" }} />
				<SelectedProductDetailContainer>
					<ImageContainer
						onError={(event) => addDefaultSrc(event)}
						src={`/static-assets/images/renderings/${getCorrectCase(
							selectedProduct?.category
						)}${getCorrectCase(selectedProduct?.form)}${
							selectedProduct?.shape !== "N/A"
								? getCorrectCase(selectedProduct?.shape)
								: ""
						}.png`}
						{...{ bgColor: lighterGray }}
					/>
					<GenericSelectedProductDetail direction="column">
						<FlexItemContent>
							<Typography size="md" color={white}>
								{selectedProduct?.fastPath}
							</Typography>
						</FlexItemContent>
						<FlexItemContent>
							<Typography size="md" color={lightGray}>
								{selectedProduct?.altPartNumber?.replace(/<br\/>/gi, "") ||
									selectedProduct?.materialMaster}
							</Typography>
						</FlexItemContent>
						<FlexItemContent>
							<Typography size="md" color={lightGray}>
								{selectedProduct?.description}
							</Typography>
						</FlexItemContent>
						<FlexItemContent css={marginTop}>
							<Label color={lightGray} size="sm">
								{content.leadTime}
							</Label>
							<Typography color={white} size="md">
								{getLeadTimeRangeDisplay(leadTimeAndDeliveryDate, language)}
							</Typography>
						</FlexItemContent>
						<FlexItemContent css={!isRetrieving ? fadeIn : ""}>
							{isZero ? (
								<Typography color={white} size="md">
									{content.priceShownInCart}
								</Typography>
							) : (
								<>
									{canPriceView && (
										<>
											<Label color={lightGray} size="sm">
												{content.priceAt
													.replace(
														"%updatedQuantity%",
														Number.isNaN(convertedQuantity)
															? emDash
															: convertedQuantity.toString()
													)
													.replace(
														"%qtyUom%",
														getDisplayUom(
															selectedProduct.form,
															qtyUom,
															"quantity"
														)
													)}
											</Label>
											<Typography color={white} size="xl">
												<>
													<Typography color={white} size="md">
														{currencyType}&nbsp;
													</Typography>
													{isZero
														? content.tbd
														: validPriceInfo
														? displayPrice(convertedPrice)
														: emDash}
													&nbsp;
													<Typography color={lightGray} size="md">
														/&nbsp;
														{getDisplayUom(
															selectedProduct.form,
															priceUom,
															"price"
														)}
													</Typography>
												</>
											</Typography>
										</>
									)}
								</>
							)}
						</FlexItemContent>
					</GenericSelectedProductDetail>
				</SelectedProductDetailContainer>
				<Divider color={white} {...{ opacity: "0.1" }} />
				<LinkButtonContainer>
					<Link
						gatsby={false}
						to={navigateToProductDetails(
							selectedProduct?.id + getQueryString(),
							selectedProduct?.altPartNumber,
							"/store"
						)}
					>
						<Button
							shape="circle"
							label={content.productDetails}
							rightIcon="forward"
							size="xs"
							foreground={"light"}
							onClick={onProductDetailsClick}
							circleLabelOrient="right"
							background={primaryBrand}
						/>
					</Link>
				</LinkButtonContainer>
			</SelectedProductContainer>
			<SliderMenu
				{...{ theme }}
				ref={creatDomNodeRef(`more-you-buy-menu`)}
				className="more-you-buy-menu slide-close"
				css={{ top: 100 }}
			>
				<Flex css={{ height: windowSize[1] - 100, overflowY: "scroll" }} direction="column">
					<MoreYouBuyHeader theme={theme}>
						<Flex alignItems="center">
							<FlexItem grow={1}>
								<Flex
									justifyContent="flex-start"
									onClick={() => setMoreYouBuyExpanded(false)}
								>
									<FlexItem alignSelf="center" css={{ marginLeft: 20 }}>
										<Icon
											icon="chevron-left"
											size="md"
											onClick={() => setMoreYouBuyExpanded(false)}
											color={white}
											circled={true}
											css={{ borderColor: gray }}
										/>
									</FlexItem>
									<FlexItem
										css={css`
											padding-left: 10px;
										`}
									>
										<Typography
											variant="p"
											color={theme.colors.tertiary.label}
											size="md"
										>
											{content.back}
										</Typography>
									</FlexItem>
								</Flex>
							</FlexItem>
							<FlexItem>
								<Flex
									css={{
										height: "50px",
										width: "50px",
										borderLeft: `1px solid ${rgba(white, 0.1)}`,
									}}
									justifyContent="center"
									alignItems="center"
								>
									<Icon
										icon="close"
										size="sm"
										onClick={() => setMoreYouBuyExpanded(false)}
										color={theme.colors.primary.white}
									/>
								</Flex>
							</FlexItem>
						</Flex>
					</MoreYouBuyHeader>
					<div css={{ padding: "20px 0px" }}>
						<Typography
							color={secondaryBrand}
							variant="h3"
							css={{ margin: "0px 20px" }}
						>
							{content.theMoreYouBuyHeader}
						</Typography>
						<Typography color={gray} variant="h3" css={{ margin: "0px 20px 20px" }}>
							{content.theMoreYouSaveHeader}
						</Typography>
						<Flex justifyContent="space-between" css={{ margin: "0px 20px" }}>
							<Typography size="md" color={secondaryBrand}>{`${
								content.quantity
							}, ${getDisplayUom(
								selectedProduct.form,
								qtyUom,
								"quantity"
							)}`}</Typography>
							<Typography size="md" color={secondaryBrand}>{`${
								content.price
							}, ${getDisplayUom(
								selectedProduct.form,
								priceUom,
								"price"
							)}`}</Typography>
						</Flex>
						<Divider
							color={primaryBrand}
							{...{ opacity: "1" }}
							css={{ margin: "10px 20px" }}
						/>
						{filteredDiscountedPrice.map((row: DiscountPriceModel, index: number) => {
							return (
								<Flex key={index}>
									<MoreYouBuyRowContent
										justifyContent="space-between"
										alignItems="center"
									>
										<Flex alignItems="center">
											<Typography color={primaryBrand} size="sm">
												{row.end
													? `${row.start}-${row.end}`
													: `${row.start}+`}
											</Typography>
										</Flex>
										<Flex>
											<Typography
												color={secondaryBrand}
												size="sm"
											>{`${displayPrice(
												Number(Number(row.price).toFixed(2))
											).substring(1)}`}</Typography>
										</Flex>
									</MoreYouBuyRowContent>
								</Flex>
							);
						})}
					</div>
				</Flex>
			</SliderMenu>
		</>
	);
};

export default SelectedProductMobile;
